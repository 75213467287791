.header {
  color: var(--trietray, #262a68);
  /* title-s-strong */
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 30px */
}
.subHeader {
  color: #000;
  /* body-strong */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 19.2px */
  padding-bottom: 10px;
}
.uploadArea {
  border-radius: 5px;
  background: #f8fafc;
  margin: 40px 0;
  padding: 20px;
}
.submit {
  padding: 8px 16px;
  border-radius: 5px;
  background: var(--button-green, #00ca8f);
  color: var(--neutral, #fbfdff);
  /* body-s-strong */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 15.6px */
  margin-bottom: 100px;
}
