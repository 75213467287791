.footer {
  margin-top: 100px;
  background: #0f172a;
  padding: 70px 0 110px;
  color: #fff;
}
.footer h2 {
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 24px;
}
.footer p {
  margin-bottom: 20px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.sup {
  margin: 50px 0 40px;
}
.sup h2 {
  margin-bottom: 10px;
}
.sup p {
  /* body */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.sup2 h2 {
  margin-bottom: 10px;
}
.sup2 p {
  margin-bottom: 0;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
}
