@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~react-image-gallery/styles/css/image-gallery.css";

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
    padding-right: 6rem;
    padding-left: 6rem;
  }
}

body {
  margin: 0;
  font-family: "Inter" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8fafc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navBar {
  margin-top: 72px;
  margin-bottom: 90px;
}
.navBar a,
.navBar button {
  font-weight: 700;
  color: #0f172a;
  margin: 0 12px;
}
.text-white {
  color: #fff !important;
}

.home-link {
  margin-right: 65px;
}

.login-links {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.search-input {
  min-width: 350px;
  max-width: 90%;
}

.filter-wrapper {
  margin-top: 24px;
  width: 100%;
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  flex-wrap: wrap;
}

.filter-input-wrapper {
  display: flex;
  align-items: center;
  flex: 1;
  color: #49475c;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  min-width: 140px;
  text-transform: capitalize;
}

.filter-input-wrapper-2 {
  color: #49475c;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  min-width: 140px;
  text-transform: capitalize;
}
.filter-input-wrapper-2 label {
  display: block;
}

.filter-wrapper .filter-input-wrapper:not(:last-of-type):after {
  content: "";
  border-left: 1px solid #c0b8d4;
  margin: 0 8px;
  height: 100%;
}

.filter-input-wrapper input {
  color: #49475c;
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
}
.filter-input-wrapper input::placeholder {
  color: #49475c;
}
.promoted-container {
  border-radius: 5px;
  background: #ebf2fd;
  gap: 24px;
  padding: 16px 0;
  margin-top: 90px;
  margin-bottom: 50px;
  flex-wrap: wrap;
}
.offers-container {
  border-radius: 5px;
  gap: 24px;
  padding: 16px 0;
  margin-top: 90px;
  margin-bottom: 50px;
  flex-wrap: wrap;
}
.promoted-container .header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 16px;
  margin-bottom: 13px;
}
.promoted-container h2 {
  color: var(--trietray, #262a68);
  /* title-s-strong */
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 30px */
  margin-right: 16px;
}

.promoted-container .promotion-filter {
  margin-left: auto;
}
.promoted-container .filter-element {
  margin: 0 12px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
}
.promoted-container .filter-element:hover {
  text-decoration: underline;
}

.promoted-container .filter-element.active {
  color: var(--secondary, #e13505);
  text-decoration: underline;
}

.offer-card {
  width: 287px;
  box-shadow: 0px 4px 25px 0px rgba(69, 115, 168, 0.25);
  border: 8px;
  overflow: hidden;
}

@media screen and (min-width: 1280px) and (max-width: 1560px) {
  .offers-container,
  .promoted-container {
    gap: 24px 12px;
  }
  .offer-card {
    width: 240px;
  }
}

.offer-card .offer-content {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: calc(100% - 191px);
}
.offer-card .offer-content .details {
  margin-bottom: 32px;
  width: 100%;
}

.offer-card .offer-content button {
  width: 100%;
}

.offer-card .offer-content .details h3 {
  color: #000;
  /* body-strong */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  margin-bottom: 16px;
}

.offer-card .offer-content .details .detail {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 15.6px */
}
.offer-card .offer-content .details .detail .value {
  font-weight: 700;
}

.offer-card .offer-content .price-area {
  margin-top: auto;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.offer-card .offer-content .price-area .country-details {
  display: flex;
  align-items: center;
}
.offer-card .offer-content .price-area .country-details img {
  height: 18px;
  margin: 0 3px;
}

.offer-card .offer-content .price {
  color: #000;
  /* body-l-strong */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
}

.offer-card .image-wrapper {
  width: 287px;
  height: 191px;
  max-width: 100%;
}

.offer-card .image-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.image-gallery-slide img {
  border-radius: 5px 5px 0px 0px;
}
.image-gallery-thumbnail-image {
  border-radius: 5px;
}

.cutsom-select::after {
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  right: 20px;
  bottom: 9px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='20' viewBox='0 0 20 20' aria-hidden='true' focusable='false' fill='%23CCCCCC' %3E%3Cpath d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'%3E%3C/path%3E%3C/svg%3E");
}
.paginate {
  display: flex;
  width: 100%;
  text-align: center;
  margin-top: 50px;
}
.paginate ul {
  width: 100%;
  justify-content: center;
  display: flex;
}

.paginate ul li {
  padding: 5px;
}
.paginate ul li:hover,
.paginate ul li a[aria-current="page"] {
  font-weight: bold;
}

.fixed-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.min-w-320 {
  min-width: 280px;
}

.add-offer {
  margin-top: 2rem;
  text-align: center;
}

.add-offer a {
  color: var(--neutral, #fbfdff);
  /* body-s-strong */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 15.6px */
  padding: 8px 16px;
  border-radius: 5px;
  background: var(--button-green, #00ca8f);
}

.bg-green-500 {
  background-color: #00ca8f !important;
}

.text-xs {
  font-size: 0.75rem; /* 12px */
  line-height: 1rem; /* 16px */
}

.text-sm {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
}

.bg-blue {
  background: #f8fafc;
  width: 100vw;
  height: 781px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.similar-wrapper .offers-container {
  margin-top: 1rem;
}

.inp-wrap {
  position: relative;
}

.input-error {
  color: red;
  font-size: 12px;
  position: absolute;
  bottom: -20px;
  right: 20px;
}

.isFilter {
  display: flex;
  align-items: center;
}
.isFilter label {
  margin-bottom: 0;
  margin-left: 10px;
}

.checkbox {
  display: flex;
  align-items: center;
}

.checkbox label {
  margin-bottom: 0;
  margin-left: 10px;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #e84e0f;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-toggle {
  display: none;
}

.menu-button::before {
  content: "";
  margin-top: -8px;
}

.menu-button::after {
  content: "";
  margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

@media screen and (max-width: 992px) {
  .App {
    margin-top: 120px;
  }
  .navBar {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    margin: 0;
    z-index: 2222;
    box-shadow: 0px 0px 9px 3px rgba(41, 41, 41, 0.25);
    display: flex;
    padding: 2rem;
    align-items: center;
    justify-content: space-between;
  }
  .menu-button-container {
    display: flex;
  }
  .navInner {
    transform: translateX(-500px);
    padding: 25px 15px;
    width: 100% !important;
    height: 550px;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 80px;
    background: #fff;
    z-index: 22222;
    left: 0;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  #menu-toggle:checked ~ .navInner {
    transform: translateX(0);
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .navInner a {
    display: block;
  }
  .login-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
    margin-top: auto;
    margin-bottom: 4rem;
    gap: 0.75rem;
  }
  .filter-wrapper {
    display: block;
  }
  .filter-wrapper button {
    margin-top: 20px;
    font-size: 14px;
  }
  .filter-input-wrapper {
    width: 100%;
  }
  .promotion-filter {
    display: none;
  }
  .advancedBoxInner {
    display: block;
  }
  .advancedBoxInner .inp-wrap > .flex > .w-full {
    padding-left: 0;
    padding-right: 0;
  }

  .advancedBoxInner .inp-wrap > .flex > .w-full.text-center {
    padding: 0 5px;
    font-size: 13px;
    width: 30px;
    text-align: center;
    transform: translateY(-8px);
  }
  .advancedBoxInner .inp-wrap input {
    font-size: 13px;
  }

  .grid-cols-4 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .grid-cols-2 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .image-gallery-thumbnails-wrapper {
    display: none !important;
  }
  p {
    font-size: 14px;
  }
  .image-gallery-slide-wrapper.image-gallery-thumbnails-left,
  .image-gallery-slide-wrapper.image-gallery-thumbnails-right {
    width: 100%;
  }
  .image-gallery-fullscreen-button {
    display: none;
  }
  .fixed.top-0.w-screen.h-screen.bg-main-dark.bg-local.flex.justify-center.align-middle {
    z-index: 999999999;
  }
}

.hover\:bg-green-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity)) !important;
}

h1.content-page-title {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 16px;
}

.content-page-content a {
  text-decoration: underline;
}
.content-page-content ul {
  display: block;
  list-style-type: disc;
  margin-left: 25px;
}

.content-page-content li {
  margin: 5px 0;
}

.content-page-content h2 {
  font-weight: bold;
}

.rel {
  position: relative;
}
.msg-counter {
  position: absolute;
  font-size: 11px;
  height: 14px;
  width: 14px;
  background-color: #e13505;
  color: #fff;
  border-radius: 50%;
  line-height: 1;
  top: -4px;
  right: -12px;
}

.msg-counter--topic {
  position: absolute;
  font-size: 11px;
  height: 14px;
  width: 14px;
  background-color: #e13505;
  color: #fff;
  border-radius: 50%;
  line-height: 1;
  top: 2px;
  right: 2px;
  z-index: 22;
}
.msg-counter--inner {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(2px);
}

.msg-resp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.msg-text-response {
  border-radius: 6px;
  border: solid 1px #cccccc;
  width: 100%;
  min-height: 60px;
  padding: 5px;
  max-width: calc(100% - 140px);
}
.msg-sub {
  padding: 8px 16px;
  border-radius: 5px;
  background: var(--button-green, #00ca8f);
  color: var(--neutral, #fbfdff);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  width: 130px;
  margin-left: auto;
}
@media screen and (max-width: 768px) {
  .msg-resp {
    display: block;
    text-align: right;
  }
  .msg-text-response {
    max-width: 100%;
    margin-bottom: 10px;
    text-align: left;
  }
  .msg-sub {
  }
}

.react-international-phone-input {
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.25rem;
}


input[type="month"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  }