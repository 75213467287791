.listingWrapper .mainDetails {
  border-radius: 5px;
  background: #f8fafc;
  padding: 40px 40px 40px 70px;
  margin-bottom: 50px;
}
.listingWrapper .mainDetails .title {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  justify-content: space-between;
}
.listingWrapper .mainDetails h1 {
  color: #000;
  /* title-s-strong */
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.mainWrap {
  display: flex;
  align-items: flex-start;
}
.imageContainer {
  width: 100%;
  max-width: 600px;
  display: inline-block;
}
.infoWrapper {
  width: calc(100% - 600px);
  padding-left: 30px;
  margin-left: auto;
}
@media screen and (max-width: 1200px) {
  .imageContainer {
    width: 100%;
    max-width: 100%;
  }
}
body {
  background: #fff;
}
.priceInfo {
  padding: 10px 20px;
  background: #fff;
  display: flex;
  align-items: center;
  margin: 11px 0;
  justify-content: space-between;
}
.priceInfo p {
  color: var(--primary, #0f172a);
  /* title-s-strong */
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.priceInfo button {
  border-radius: 5px;
  background: var(--button-green, #00ca8f);
  padding: 8px 16px;
  color: #fff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.priceInfoSecond {
  padding: 10px 20px;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 11px 0;
}
.priceInfoSecond .infoBit {
  width: 45%;
  margin: 10px 5% 10px 0;
}
.priceInfoSecond .infoBit .bitElement {
  color: #000;
  /* body-s-strong */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 15.6px */
}
.extraInfo {
  margin: 45px 15px 10px;
}
.extraInfo .location {
  display: flex;
  align-items: center;
  color: #000;
  /* body */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.extraInfo .location img {
  margin-right: 15px;
}
.contact {
  margin: 35px 15px;
  display: flex;
  align-items: center;
  color: var(--button, #4785ff);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.contact img {
  margin-left: 10px;
}
.technical {
  border-radius: 5px;
  background: #f8fafc;
  padding: 30px 40px;
}
.tabBut {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-right: 24px;
  margin-top: 2rem;
}
.tabWrapper {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.tabWrapper .detail {
  display: flex;
  justify-content: flex-start;
  margin: 10px 0;
}
.tabWrapper .detail .title {
  width: 191px;
  text-transform: capitalize;
}
.tabWrapper .detail .value {
  color: #000;
  text-transform: capitalize;
  /* body-strong */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.tabWrapper .detail .value.lowers {
  text-transform: lowercase;
}
.tabWrapper .titleInner {
  font-family: Inter;
  font-size: 16px;
  font-style: bold;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 1rem;
  margin-top: 2rem;
}
.tabWrapper .listWrap {
  margin-left: 30px;
}
.tabWrapper .element {
  list-style-type: disc;
}
.similarTab {
  margin-top: 70px;
  margin-bottom: 200px;
}
.similarTab .h2 {
  color: var(--trietray, #262a68);
  /* title-s-strong */
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 30px */
}
@media screen and (max-width: 992px) {
  .listingWrapper .mainDetails {
    padding: 20px;
  }
  .listingWrapper .mainDetails .title {
    display: block;
  }
  .listingWrapper .mainDetails .title h1 {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .mainWrap {
    display: block;
  }
  .infoWrapper {
    margin-left: 0;
    flex-direction: column;
    padding-left: 0;
    width: 100%;
  }
  .priceInfo p {
    font-size: 16px;
  }
  .tabWrapper svg {
    width: 100%;
  }
  .tabWrapper .detail .title {
    width: 110px;
    font-size: 13px;
  }
  .tabWrapper .detail .value {
    font-size: 13px;
  }
}
